import _ from 'lodash';

import { DecodeOptions, LocalRecogniserModelTypes, ModelLinks } from './types';
// import { test_session_creation } from '../casrjs/src/casr/utils';
import {
  checkModelsCached,
  clearModelData,
  getCachedModelVersions,
  prefetchAllModels,
} from './cache';
import logger from 'src/lib/logger';
import { modelLinksToModelVersions } from './utils';

export class LocalRecogniser extends EventTarget {
  modelStatus?: string;
  isReady: boolean = false;

  private _worker?: Worker;

  private _initDefered?: Deferred<void>;

  private _modelVersions: any;

  static async isPlatformSupported() {
    // const onnxModel = await getData('/model.onnx');
    // return await test_session_creation(onnxModel, DefaultExecutionProvider);
    return true;
  }

  async init(modelLinks?: ModelLinks) {
    let modelVersions = modelLinks
      ? modelLinksToModelVersions(modelLinks)
      : getCachedModelVersions();

    if (this.isReady) {
      const sameModels = _.isEqual(modelVersions, this._modelVersions);
      if (sameModels) {
        return;
      }
    }

    if (this._initDefered) {
      return await this._initDefered.promise;
    }
    this._initDefered = createDeferred();

    this._setIsReady(false);

    this._setModelStatus('model_loading');

    if (modelLinks) {
      try {
        await prefetchAllModels(modelLinks, this._changeProgress.bind(this));
      } catch (error) {
        modelVersions = getCachedModelVersions();
        console.error('Failed to load casr models', error);
      }
    }

    if (!LocalRecogniserModelTypes.some((t) => checkModelsCached(t))) {
      this._initDefered = undefined;
      logger.error(`No recognition model files`);
      throw new Error('No recognition model files');
    }

    if (!checkModelsCached('shared')) {
      this._initDefered = undefined;
      logger.error(`No shared model files`);
      throw new Error('No shared files for model');
    }

    if (!this._worker) {
      this._worker = (await this._createWorker()) as Worker;
      this._worker.onmessage = this._handleWorkerMessage.bind(this);
    }
    this._worker?.postMessage({ command: 'init', args: modelVersions });

    await this._initDefered.promise;
  }

  private _createWorker() {
    return new Promise((resolve, reject) => {
      const worker = new Worker(
        new URL('./local_recogniser_async_worker.ts', import.meta.url),
      );

      const onReadyMessage = (event: MessageEvent) => {
        if (event.data.event === 'ready') {
          worker.removeEventListener('message', onReadyMessage);
          resolve(worker);
        }
      };

      worker.addEventListener('message', onReadyMessage);

      worker.onerror = (error) => {
        reject(error);
      };
    });
  }

  private _handleWorkerMessage(e: MessageEvent) {
    const { event } = e.data;

    if ('init_started' === event) {
      logger.info('[recogniser_async] init_started');
      this._setIsReady(false);
      this._setModelStatus('model_loading');
      this._changeProgress('Loading models into memory');
    } else if ('init_finished' === event) {
      logger.info('[recogniser_async] init_finished');
      const { modelVersions } = e.data;
      this._modelVersions = modelVersions;
      this._setModelStatus('model_ready');
      this._setIsReady(true);
      this._initDefered?.resolve();
      this._initDefered = undefined;
      this._changeProgress('Loading models finished');
    } else if ('init_error' === event) {
      const { message } = e.data;
      logger.info(`[recogniser_async] init_error: ${message}`);

      this._initDefered?.reject(new Error(message));
      this._initDefered = undefined;
      this._changeProgress('Loading models failed');
    } else if ('decode' === event) {
      const { result } = e.data;
      this.dispatchEvent(new CustomEvent('decode', { detail: result }));
    } else if ('log_info' === event) {
      const { message } = e.data;
      logger.info(message);
    } else if ('log_error' === event) {
      const { message } = e.data;
      logger.error(message);
    }
  }

  cleanUp() {
    clearModelData();
    this._setModelStatus();
    this._setIsReady(false);

    this._modelVersions = undefined;

    this._worker?.terminate();
    this._worker = undefined;
  }

  feed(chunk: Array<number>, convertCommands: boolean) {
    const options: DecodeOptions = { convert_commands: convertCommands };
    this._worker?.postMessage({
      command: 'feed',
      args: { data: chunk, options },
    });
  }

  stopFeed(convertCommands: boolean = false) {
    const options: DecodeOptions = { convert_commands: convertCommands };
    this._worker?.postMessage({ command: 'stop_feed', args: { options } });
  }

  private _setModelStatus(status?: string) {
    this.modelStatus = status;
    this.dispatchEvent(
      new CustomEvent('change', { detail: { modelStatus: status } }),
    );
  }

  private _setIsReady(value: boolean) {
    this.isReady = value;
    this.dispatchEvent(new CustomEvent('change', { detail: { ready: value } }));
  }

  private _changeProgress(stage: string, progress?: number) {
    this.dispatchEvent(
      new CustomEvent('progress', { detail: { stage, progress } }),
    );
  }
}

type Deferred<T> = {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
};

function createDeferred<T>(): Deferred<T> {
  let deferred: Partial<Deferred<T>> = {};

  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred as Deferred<T>;
}
