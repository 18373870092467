export enum ExecutionProvider {
  webnnNpu = 'webnn-npu',
  webnnGpu = 'webnn-gpu',
  webnnCpu = 'webnn-cpu',
  wasm = 'wasm',
}

//Also a priority list
export const LocalRecogniserModelTypes = ['acoustic_icefall_pts7s'];

export const DefaultExecutionProvider = ExecutionProvider.wasm;

export const defaultVadParams = {
  mfcc_execution_provider: DefaultExecutionProvider,
  vad_execution_provider: DefaultExecutionProvider,
  start_of_speech_threshold: 0.646,
  end_of_speech_threshold: 0.154,
  speech_max_length_ms: 10000,
};

export const defaultAsrParams = {
  fbank_execution_provider: DefaultExecutionProvider,
  encoder_execution_provider: DefaultExecutionProvider,
  decoder_execution_provider: DefaultExecutionProvider,
  joiner_execution_provider: DefaultExecutionProvider,
  blank_id: 0,
  // 'decoding_method': 'greedy_search',
  decoding_method: 'modified_beam_search',
  num_active_paths: 10,
  topk: 3,
};

export type LocalRecogniserResult = {
  event: string;
  chunk_id: number;
  segment_id: number;
  vad: {
    audio: Array<number>;
  };
  asr?: {
    decoding_done: boolean;
    text: string;
    normalized_text?: string;
  };
};

export interface ModelLinks {
  [model_type: string]: {
    version: string;
    links: string[];
    modelmeta?: string;
  };
}

export interface ModelVersion {
  [model_type: string]: string;
}

export interface DecodeOptions {
  convert_commands: boolean;
}
